import styled from 'styled-components';
import IconCheck from '../../images/icon-check.png';
import IconArrowYellowRight from '../../images/icon-arrow-yellow-right.png';

export const HeaderH2 = styled.h2`
  text-align: ${(props) => (props.$textAlign ? 'center' : 'left')};
  position: relative;
  margin: 0 0 35px 0;
  font-family: 'MrAlex', sans-serif;
  font-size: 45px;
  line-height: 50px;
  color: #00539d;
  @media (max-width: 1399px) {
    font-size: 40px;
    line-height: 45px;
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    font-size: 30px;
    line-height: 35px;
  }
`;
export const HeaderH3 = styled.h3`
  margin: 0 0 35px 0;
  font-family: 'HelveticaNeue-Light', sans-serif;
  font-size: 32px;
  line-height: 37px;
  color: #00a6e8;
  text-align: ${(props) => (props.$textAlign ? 'center' : 'left')};
  @media (max-width: 1399px) {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    font-size: 25px;
    line-height: 30px;
  }
`;
export const HeadingH4 = styled.h4`
  padding: 0 0 15px 0;
  margin: 0 0 35px 0;
  font-family: 'HelveticaNeue-Light', sans-serif;
  font-size: 24px;
  line-height: 29px;
  color: rgba(0, 0, 0, 0.6);
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  font-weight: normal;
  @media (max-width: 1399px) {
    font-size: 22px;
    line-height: 27px;
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 25px;
  }
`;

export const ParaText = styled.p`
  margin-bottom: 40px;
  @media (max-width: 1399px) {
    margin-bottom: 30px;
  }
  i {
    font-style: italic;
    margin-bottom: 0;
    font-size: 18px;
    line-height: 24px;
    @media (max-width: 1399px) {
      font-size: 15px;
      line-height: 22px;
    }
    @media (max-width: 767px) {
      font-size: 13px;
      line-height: 20px;
    }
  }
  b {
    font-family: 'HelveticaNeue-Bold', arial, sans-serif;
    font-weight: normal;
    font-style: normal;
  }
`;

export const ImageStyle = styled.img`
  height: auto;
  width: auto;
  border: 0;
  vertical-align: top;
  margin: 0 0 40px 0;
  @media (max-width: 1399px) {
    margin-bottom: 30px;
  }
`;
export const SuperScriptStyle = styled.sup`
  vertical-align: top;
  font-size: 18px;
  line-height: 30px;
`;
export const UnorderedList = styled.ul`
  margin: 0 0 30px 0;
  padding: 0;
`;
export const ListItem = styled.li`
  padding: 0 0 15px 50px;
  background: url(${IconCheck}) no-repeat 0 1px;
  background-size: 30px auto;
  list-style: none;
  margin: 0;
  p {
    margin: 0;
  }

  @media (max-width: 767px) {
    padding-top: 1px;
    padding-left: 40px;
    background-position: 0 0;
    background-size: 25px auto;
  }
`;
export const ListItemArrow = styled.li`
  padding: 0 0 15px 30px;
  background: url(${IconArrowYellowRight}) no-repeat 0 9px;
  background-size: 10px auto;
  list-style: none;
  margin: 0;
  p {
    margin: 0;
  }
  @media (max-width: 1399px) {
    background-position: 0 9px;
  }
  @media (max-width: 767px) {
    background-position: 0 5px;
  }
`;
export const LinkStyle = styled.a`
  text-decoration: none;
  color: #00a6e8;
  transition: 0.25s ease-out;
`;
export const ListItemNumber = styled.li`
  position: relative;
  margin: 0;
  padding: 15px 0 15px 65px;
  p {
    margin: 0px;
  }
  @media (max-width: 767px) {
    padding-left: 50px;
  }
  ::before {
    content: counter(standard-counter);
    counter-increment: standard-counter;
    position: absolute;
    left: 0px;
    width: 40px;
    text-align: center;
    padding: 6px 0 0 0;
    font-family: 'MrAlex', arial, sans-serif;
    font-size: 30px;
    line-height: 30px;
    color: #ffc905;
    border: 2px solid #ffc905;
    border-radius: 50%;

    @media (max-width: 767px) {
      top: 11px;
      width: 30px;
      height: 30px;
      padding: 3px 0 0 0;
      font-size: 20px;
      line-height: 20px;
    }
  }
`;
export const OrderListItem = styled.ol`
  margin: 0 0 30px 0;
  list-style-type: none;
  counter-reset: standard-counter;
  position: relative;
  padding: 0;
`;
export const ButtonStyle = styled.a`
  display: inline-block;
  position: relative;
  top: 0;
  padding: 15px 35px;
  margin-right: 10px;
  margin-bottom: 40px;
  font-family: 'MrAlex', sans-serif;
  font-size: 24px;
  line-height: 28px;
  text-transform: uppercase;
  font-weight: bold;
  color: #00a6e8;
  border: 2px solid #00a6e8;
  box-shadow: 0 0 0 2px rgb(255 255 255 / 0%);
  border-radius: 100px;
  cursor: pointer;
  text-decoration: none;

  @media (max-width: 1399px) {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 30px;
  }
  @media (max-width: 1199px) {
    font-size: 25px;
    line-height: 25px;
  }

  @media (max-width: 767px) {
    font-size: 22px;
    line-height: 25px;
  }
  i {
    @media (max-width: 767px) {
      font-size: 22px;
      line-height: 25px;
    }
  }
  :hover {
    top: -5px;
    color: #f7991c;
    border-color: #f7991c;
    box-shadow: 0 5px 0 0 #f7991c;
  }
`;
